import Vue from 'vue'
import VueRouter from 'vue-router'
import Work from '../views/Work.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Work',
    component: Work
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
  ,
  {
    path: "/project/:id",
    name: "Project",
    component: () => import(/* webpackChunkName: "project" */ "../views/Project.vue")
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  //mode: "history",
  routes
})

export default router
