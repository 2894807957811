<template>
  <div class="work">
    <h1 id="logo">Maika Dieterich</h1>

    <figure v-for="projectdetail in projectdetails" v-bind:key="projectdetail.title">
      <router-link :to="{name: 'Project', params: {id:projectdetail.id}}">
        <img @mousemove="mouseMove"
             @mouseenter="mouseEnter(projectdetail.id)"
             @mouseleave="mouseLeave"
             class="homeImg" :src="require(`@/assets/${projectdetail.images[0]}`)" :alt="projectdetail.title">
      </router-link>
      <h1 class="movableType" :id="'projectTitle'+projectdetail.id" v-show="visible && currentId === projectdetail.id">{{ projectdetail.title }}</h1>
    </figure>

  </div>
</template>

<script>
// @ is an alias to /src
import projects from "@/projects.js";

export default {
  name: 'Work',
  components: {},
  data() {
    return{
      projectdetails: projects.projectdetails,
      visible : true,
      currentId: null
    }
  },
  methods: {
    mouseEnter(id) {
      this.currentId = id;
      this.$el.addEventListener('mousemove', this.mouseMove, false);
      this.visible = true;
    },
    mouseLeave() {
      this.visible = false;
      this.currentId = null;
      //this.$el.removeEventListener('mousemove', this.mouseMove());
    },
    mouseMove(event) {
      //console.log(event.clientX, event.clientY);
      if(this.visible){
        let elementId = 'projectTitle' + this.currentId;
        document.getElementById(elementId).style.top = event.screenY+'px';
        document.getElementById(elementId).style.left = event.screenX+'px';
      }
    }
  }

}
</script>
<style>
.movableType {
  top: 50vh;
  left: 40vw;
}
#logo {
  font-size: 50%;
  position: fixed;
  text-align: right;
  top: 0;
  right: 2vw;
  font-feature-settings: "swsh" 2;
}

.homeImg {
  margin: 25vh 22vw 20vh 25vw;
  width: 45vw;
}

@media screen and (max-width: 992px) {
  .homeImg {
    margin: 10vh 2vw 10vh 0vw;
    width: 75vw;
  }
  .homeImg:hover{
    filter: none;
  }
  .movableType{
    display: none;
  }
  #logo {
    font-size: 75%;
  }
}

.homeImg:hover{
  filter: opacity(30%);
}


router-link {
  text-underline: none;
  color: black;
}
</style>